export interface Vector {
  x: number;
  y: number;
}

export interface Line {
  start: Vector;
  end: Vector;
}

export interface Block extends Line {
  id: string;
  disabled?: boolean;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function svgPathToLines(svgPath: string) {
  let pathData = svgPath.match(/[A-Z][^A-Z]*/g);
  let lines: Array<Line> = [];
  let lastX: number, lastY: number;

  if (!pathData) return [];

  pathData.forEach((segment) => {
    let command = segment[0];
    let coords = segment
      .slice(1)
      .trim()
      .split(/[\s,]+/)
      .map(Number);

    if (command === 'M') {
      // Move to command, start a new subpath
      lastX = coords[0];
      lastY = coords[1];
    } else if (command === 'L') {
      // Line to command, create a line segment
      let x = coords[0];
      let y = coords[1];
      lines.push({
        start: {
          x: lastX,
          y: lastY,
        },
        end: {
          x: x,
          y: y,
        },
      });
      lastX = x;
      lastY = y;
    } else if (command === 'H') {
      // Horizontal line to command, create a line segment
      let x = coords[0];
      lines.push({
        start: {
          x: lastX,
          y: lastY,
        },
        end: {
          x: x,
          y: lastY,
        },
      });
      lastX = x;
    }
  });

  return lines;
}

export async function getPathFromSVG(svgUri: string): Promise<Array<Line>> {
  const svgContent = await fetch(svgUri).then((response) => response.text());

  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');

  const paths = svgDoc.querySelectorAll('path');

  let lines: Array<Line> = [];

  paths.forEach((path) => {
    const d = path.getAttribute('d');
    if (d) {
      lines = lines.concat(svgPathToLines(d));
    }
  });

  return lines;
}

export function linesIntersect(line: Line, line2: Line): boolean {
  return findIntersection(line, line2) !== null;
}

export function findIntersection(line: Line, line2: Line) {
  const x1 = line.start.x;
  const y1 = line.start.y;
  const x2 = line.end.x;
  const y2 = line.end.y;

  const x3 = line2.start.x;
  const y3 = line2.start.y;
  const x4 = line2.end.x;
  const y4 = line2.end.y;

  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  if (denominator === 0) {
    return null;
  }

  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
  const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / denominator;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    return {
      x: x1 + t * (x2 - x1),
      y: y1 + t * (y2 - y1),
    };
  }

  return null;
}

export function pointInRect(point: Vector, rect: Rect) {
  return point.x >= rect.x && point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height;
}

export function rectanglesIntersect(rect1: Rect, rect2: Rect): boolean {
  return !(
    (
      rect1.x > rect2.x + rect2.width || // rect1 is to the right of rect2
      rect1.x + rect1.width < rect2.x || // rect1 is to the left of rect2
      rect1.y > rect2.y + rect2.height || // rect1 is below rect2
      rect1.y + rect1.height < rect2.y
    ) // rect1 is above rect2
  );
}

export function distance(a: Vector, b: Vector) {
  return Math.abs(Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)));
}

export function distanceToRect(point: Vector, rect: Rect) {
  const nearestX = Math.max(rect.x, Math.min(point.x, rect.x + rect.width));
  const nearestY = Math.max(rect.y, Math.min(point.x, rect.y + rect.height));

  const dx = point.x - nearestX;
  const dy = point.y - nearestY;

  return Math.sqrt(dx * dx + dy * dy);
}

export function distanceToLine(point: Vector, line: Line) {
  const numerator = Math.abs(
    (line.end.y - line.start.y) * point.x - (line.end.x - line.start.x) * point.y + line.end.x * line.start.y - line.end.y * line.start.x
  );
  const demoninator = Math.sqrt(Math.pow(line.end.y - line.start.y, 2) + Math.pow(line.end.x - line.start.x, 2));

  return numerator / demoninator;
}

export function oscillation(start: number, end: number, step: number) {
  const range = end - start;
  return start + Math.abs((step % (2 * range)) - range);
}

export function expandRect(rect: Rect, value: number) {
  return {
    x: rect.x - value,
    y: rect.y - value,
    width: rect.width + 2 * value,
    height: rect.height + 2 * value,
  };
}

export function moveLine(line: Line, vector: Vector) {
  return {
    start: {
      x: line.start.x + vector.x,
      y: line.start.y + vector.y,
    },
    end: {
      x: line.end.x + vector.x,
      y: line.end.y + vector.y,
    },
  };
}

export function getGradient(line: Line) {
  const deltaY = line.end.y - line.start.y;
  const deltaX = line.end.x - line.start.x;

  return { dx: deltaX, dy: deltaY, slope: deltaY / deltaX };
}

export function getVerticalSpeed(line: Line, horizontalSpeed: number) {
  const deltaY = line.end.y - line.start.y;
  const deltaX = line.end.x - line.start.x;

  // Check if the line is vertical
  if (deltaX === 0) {
    return horizontalSpeed === 0 ? deltaY : (horizontalSpeed / Math.abs(horizontalSpeed)) * deltaY;
  }

  // Calculate the slope of the line
  const slope = deltaY / deltaX;

  // Calculate the vertical speed
  return slope * horizontalSpeed;
}
