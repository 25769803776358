import { useEffect, useState } from "react";
import FormInput from "../../../Components/FormInput";
import { RenderableContent, SetState } from "../../../types";
import styles from "./selectInput.module.scss";

type Props = {
  options: RenderableContent[];
  setValue: SetState<{ value: string; new: boolean }>;
  submitErrCheck: boolean;
};

const SelectInput = ({ options, setValue, submitErrCheck }: Props) => {
  const [selectValue, setSelectValue] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  let message;

  useEffect(() => {
    if (selectValue === "newInput") {
      setValue({ value: inputValue, new: true });
    } else {
      setValue({ value: selectValue, new: false });
    }
  }, [selectValue, inputValue]);

  const [focused, setFocused] = useState(true);
  if ((selectValue === "" || selectValue === "choose") && focused === false) {
    message = "This field is required";
  }

  useEffect(() => {
    if (submitErrCheck) {
      setFocused(false);
    }
  }, [submitErrCheck]);

  return (
    <div className={styles.selectInputContainer}>
      <select
        className={`${styles.selectInput} form-select ${
          focused === false &&
          (selectValue === "" || selectValue === "choose") &&
          styles.err
        }`}
        onChange={(e) => setSelectValue(e.target.value)}
        value={selectValue}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {options}
      </select>
      <>
        <span className="err_text text-center">{message}</span>
      </>
      {selectValue === "newInput" && (
        <div key="formInput" className={styles.newInputContainer}>
          <FormInput
            type="text"
            placeholder="New"
            name="New"
            onChangeHandler={setInputValue}
            value={inputValue}
          />
        </div>
      )}
    </div>
  );
};

export default SelectInput;
