import {oscillation} from "../game/utils";
import {BaseAnimation} from "./base";

type Config = {
  speed: number;
  minOpacity?: number;
  maxOpacity?: number;
}

export class PulseAnimation extends BaseAnimation<Config> {
  run() {
    this.sprite.opacity = oscillation(this.config.minOpacity || 30, this.config.maxOpacity || 150, this.sprite.p.frameCount * 4);
  }
}
