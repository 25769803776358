import { useCallback } from "react";
import { CreateProcess, Data } from "../types";
import { SetState } from "../../../../types";

import styles from "../creatorPage.module.scss";

type Props = {
  process: CreateProcess;
  setData: SetState<Data>;
};

const imgObj = {
  value: [],
  err: "",
  new: false,
  display: styles.hidden,
  uploadReq: {
    required: false,
    nameRequired: false,
    min: 0,
    max: 0,
  },
};

const inputFieldObj = {
  value: "",
  err: "",
  display: styles.display,
};

const taskCompletedRewardType = {
  value: "",
  err: "",
  display: styles.hidden,
};

const taskCompletedSubObj = {
  value: null,
  err: "",
  display: styles.hidden,
  pattern: "^[0-9]+$",
};

const useCreateMissionDataDefaults = () => {
  const dataDefault = {
    createProcess: {
      value: ["advanceCreate"],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: ["createProcess"],
      requiredSubData: [],
    },
    category: {
      value: ["choose"],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: ["category"],
      requiredSubData: [],
    },
    categoryIcon: {
      value: [""],
      err: "",
      img: {
        ...imgObj,
        uploadReq: {
          required: true,
          nameRequired: false,
          min: 1,
          max: 1,
        },
      },
      inputField: { ...inputFieldObj },
      requiredData: [],
      requiredSubData: [],
    },
    task: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: ["task"],
      requiredSubData: [],
    },
    taskOption: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: ["taskOption"],
      requiredSubData: [],
    },
    optionsIcons: {
      value: [""],
      err: "",
      img: {
        ...imgObj,
        uploadReq: {
          required: true,
          nameRequired: true,
          min: 1,
          max: 10,
        },
      },
      requiredData: [],
      requiredSubData: [],
    },
    taskCompleted: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: ["taskCompleted"],
      requiredSubData: [
        "rewardType",
        "rewardAmount",
        "maxAllowed",
        "resetPeriod",
      ],
      rewardType: { ...taskCompletedRewardType },
      rewardAmount: { ...taskCompletedSubObj },
      maxAllowed: { ...taskCompletedSubObj },
      resetPeriod: { ...taskCompletedSubObj },
    },
    addToGame: {
      value: ["None"],
      err: "",
      img: { ...imgObj },
      requiredData: [],
      requiredSubData: [],
    },
    createMissionSummary: {
      value: ["confirm"],
      err: "",
      img: { ...imgObj },
      requiredData: [],
      requiredSubData: [],
    },
  };
  const createMissionDataDeaults = useCallback(
    ({ process, setData }: Props) => {
      if (!process || process === "advanceCreate") {
        setData(dataDefault);
      } else if (process === "quickCreate") {
        setData({
          ...dataDefault,
          createProcess: {
            value: ["quickCreate"],
            err: "",
            img: { ...imgObj },
            inputField: { ...inputFieldObj },
            requiredData: ["createProcess"],
            requiredSubData: [],
          },
          category: {
            ...dataDefault.category,
            value: ["Teacher Bonus"],
          },
          categoryIcon: {
            ...dataDefault.categoryIcon,
            img: {
              ...dataDefault.categoryIcon.img,
              value: [{ name: "", url: "karate_icon.png" }],
            },
          },
          task: {
            ...dataDefault.task,
            value: ["I did a teacher's bonus!"],
          },
          taskOption: {
            ...dataDefault.taskOption,
            value: ["image"],
          },
          taskCompleted: {
            ...dataDefault.taskCompleted,
            value: ["SuperMeCoins"],
            rewardType: {
              ...dataDefault.taskCompleted.rewardType!,
              value: "SuperMeCoins",
            },
            maxAllowed: {
              ...dataDefault.taskCompleted.maxAllowed!,
              value: 1,
            },
            resetPeriod: {
              ...dataDefault.taskCompleted.resetPeriod!,
              value: 1,
            },
            rewardAmount: {
              ...dataDefault.taskCompleted.rewardAmount!,
              value: 1,
            },
          },
        });
      }
    },
    []
  );
  return createMissionDataDeaults;
};

export default useCreateMissionDataDefaults;
