import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AdminTemplate from "../../Components/adminTemplate/AdminTemplate";
import { MissionsTable } from "../../Components/missionTable/missionsTable";

import ApiService from "../../../Services/apiServiceT";
import { RenderableContent } from "../../../types";
import Overlay from "../../../Components/overlay/Overlay";
import AddUser from "./components/AddUser";
import Modal from "../../Components/dialog/Dialog";
import { TeacherClass, UserInfo } from "./types";

const UsersPage = () => {
  const apiService = new ApiService();
  const [usersInfo, setUsersInfo] = useState<UserInfo[]>([]);
  const [teacherClasses, setTeacherClasses] = useState<TeacherClass[]>([]);
  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    content: RenderableContent;
  }>({ open: false, content: null });
  const navigate = useNavigate();

  const tableSetup = [
    { accessorKey: "fname", header: "First Name" },
    { accessorKey: "lname", header: "Last Name" },
    { accessorKey: "date", header: "Date" },
    { accessorKey: "last_mission", header: "Last Mission" },
  ];

  const clickHandler = (id: string) => {
    navigate(`/user/${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userInfoResp, err]: any = await apiService.get({
          path: "/last_missions",
          dataType: "admin",
          data: { classes: true },
        });
        if (userInfoResp) return userInfoResp;
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().then((userInfoResp) => {
      if (userInfoResp) {
        setUsersInfo(userInfoResp.data.last_missions);
        setTeacherClasses(userInfoResp.data.teacherClasses);
      }
    });
  }, []);

  const addUserDialog = (
    <AddUser
      setUsersInfo={setUsersInfo}
      teacherClasses={teacherClasses}
      setOpenDialog={setOpenDialog}
      setTeacherClasses={setTeacherClasses}
    />
  );

  const displayAddUser = (): void => {
    setOpenDialog({
      open: true,
      content: <Modal content={addUserDialog} />,
    });
  };

  const mainContent = (
    <MissionsTable
      data={usersInfo}
      tableSetup={tableSetup}
      title="Students"
      clickHandler={clickHandler}
      tooltip={{ value: "addIcon", action: displayAddUser }}
    />
  );
  return (
    <>
      {openDialog.open && (
        <Overlay
          closeHandler={(close: boolean) =>
            setOpenDialog({
              open: close,
              content: null,
            })
          }
          content={openDialog.content}
        />
      )}
      <AdminTemplate pageContent={mainContent} />
    </>
  );
};

export default UsersPage;
