import ApiService from "../../../../Services/apiServiceT";
import { RenderableContent, SetState } from "../../../../types";
import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";
import { CreatorData } from "../types";

const useDeleteMission = () => {
  const apiService = new ApiService();

  const deleteMission = async (
    missionId: string,
    setCreatorData: SetState<CreatorData | null>,
    setContent: SetState<RenderableContent>,
    closeEditMissions: () => void
  ) => {
    const deleteCall = async () => {
      try {
        const [deleteResp, err] = await apiService.delete({
          path: `/delete_mission`,
          data: { missionId: missionId },
          dataType: "admin",
        });

        if (!err) {
          return deleteResp;
        } else {
          console.log(err);
        }
      } catch (error) {
        console.log(error);
      }
    };

    let message: RenderableContent;
    const deleteResp = await deleteCall();
    if (deleteResp?.status === 200) {
      message = (
        <>
          Completed <br></br> Mission Deleted <br></br> Successfully
        </>
      );
      setCreatorData((prev) => {
        if (!prev?.missions) return prev;
        let prevMissions = [...prev?.missions];
        if (prevMissions) {
          prevMissions = prevMissions.filter(
            (missions) => missions._id !== missionId
          );
        }
        let gameboardConfig = prev.gameboardConfigs.filter((config) => {
          const hasMission = config.missionIds.some(
            (mission) => mission === missionId
          );
          return hasMission;
        });
        let prevConfigs = prev.gameboardConfigs.filter((config) => {
          const hasMission = gameboardConfig.some(
            (gameboard) => gameboard._id !== config._id
          );
          return hasMission;
        });
        const newConfigs = [...prevConfigs];
        if (gameboardConfig) {
          gameboardConfig.forEach((game) => {
            game.missionIds = game.missionIds.filter(
              (mission) => mission !== missionId
            );
            game.totalMissions = game.missionIds.length;
            newConfigs.push(game);
          });
        }

        return {
          ...prev,
          missions: prevMissions,
          gameboardConfigs: newConfigs,
        };
      });
    } else {
      message = (
        <>
          Something went wrong. <br></br> Please try again.
        </>
      );
    }

    setContent(
      <ActionSuccessStatus message={message} closeHandler={closeEditMissions} />
    );
  };
  return deleteMission;
};

export default useDeleteMission;
