import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Hidden,
  Drawer,
  List,
  ListItem,
  Box,
  ButtonBase,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";

import ApiService from "../../../Services/apiService";
import "../../../assets/main_css/main.css";
import "../../../assets/css_new/main.css";
import cssModule from "./userNavBar.module.css";
import useGetCookieHook from "../../../Hooks/useGetCookieHook";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1260,
      xl: 1920,
      // You can add a custom breakpoint
      custom: 1000,
    },
  },
});

const images = {
  mainLogo: require("../../../assets/images/website/mainLogo.png"),
  misson_done: require("../../../assets/images/userSite/missionsIcon.png"),
  trophy: require("../../../assets/images/userSite/achievementsIcon.png"),
  super_coin: require("../../../assets/images/super_coin.png"),
  learn: require("../../../assets/images/userSite/learn.png"),
  campaign: require("../../../assets/images/userSite/campaign.png"),
  // walk_to_israel: require("../../assets/images/userSite/WalkToIsraelIcon.png"),
};

const UserNavBar = () => {
  // console.log("USER NAVBAR");
  const getCookieHook = useGetCookieHook();
  const apiService = new ApiService(true);
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: "DEMO",
    rank: 1,
    coins: 0,
    level: 0,
  });

  useEffect(() => {
    const user = getCookieHook("user");

    setUserData({
      name: user?.name ? user.name.split(" ")[0] : user?.fname,
      rank: 1,
      coins: 0,
      level: 0,
      // coins: user.achievements ? user.achievements.superMeCoins : 0,
      // rank: user.achievements ? user.achievements.superMeRank : 1,
      // streak: user.achievements ? user.achievements.superMeStreak : 0,
    });
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutHandler = async () => {
    try {
      const [response, error] = await apiService.get({
        path: "/",
        dataType: "logout",
      });

      for (var cookie in Cookies.get()) {
        Cookies.remove(cookie);
      }

      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const navbar_items = {
    campaigns: (
      <a>
        <Button
          onClick={() => navigate("/userCampaigns", { replace: true })}
          className={`${cssModule.navbtn}`}
        >
          <div className={cssModule.campaign_icon_container}>
            <img
              src={images["campaign"]}
              className={`img-fluid ${cssModule.campaign_icon_img}`}
              alt=""
            />
          </div>
          <div className={cssModule.navbtn_bg}>
            <div className={cssModule.navbtn_mtext}>Campaign</div>
          </div>
        </Button>
      </a>
    ),
    achievements: (
      <a>
        <Button
          onClick={() => navigate("/achievements", { replace: true })}
          className={`${cssModule.navbtn}`}
        >
          <div className={cssModule.achievements_icon_container}>
            <img
              src={images["trophy"]}
              className={`img-fluid ${cssModule.achievements_icon_img}`}
              alt=""
            />
          </div>
          <div className={cssModule.navbtn_bg}>
            <div className={cssModule.navbtn_mtext}>Achievements</div>
          </div>
        </Button>
      </a>
    ),
    learn: (
      <a>
        <Button
          onClick={() => navigate("/learn", { replace: true })}
          className={`${cssModule.navbtn}`}
        >
          <div className={cssModule.learn_icon_container}>
            <img
              src={images["learn"]}
              className={`img-fluid ${cssModule.learn_icon_img}`}
              alt=""
            />
          </div>
          <div className={cssModule.navbtn_bg}>
            <div className={cssModule.navbtn_mtext}>Learn</div>
          </div>
        </Button>
      </a>
    ),
    missions: (
      <a>
        <Button
          onClick={() => navigate("/missions", { replace: true })}
          className={`${cssModule.navbtn}`}
        >
          <div className={cssModule.missions_icon_container}>
            <img
              src={images["misson_done"]}
              className={`img-fluid ${cssModule.missions_icon_img}`}
              alt=""
            />
          </div>
          <div className={cssModule.navbtn_bg}>
            <div className={cssModule.navbtn_mtext}>Missions</div>
          </div>
        </Button>
      </a>
    ),
    profile: (
      <Button
        className={cssModule.profile_icon_container}
        onClick={() => navigate("/profile", { replace: true })}
      >
        <a
          className={cssModule.profile_icon}
          // href="/profile"
        >
          {userData.name && userData.name[0]}
        </a>
      </Button>
    ),
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "rgba(68, 94, 170, 0.5)",
        borderRadius: "0 0 20px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box height="100px"></Box>
      <List>
        {["campaigns", "achievements", "learn", "missions", "profile"].map(
          (text, index) => (
            <ListItem
              button
              key={text}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {navbar_items[text]}
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  return (
    <div style={{ position: "relative", zIndex: "100" }}>
      <AppBar className="_navbar" style={{ minHeight: "10%", padding: "0px" }}>
        <ThemeProvider theme={theme} maxWidth="md">
          <Toolbar style={{ height: "100%" }}>
            <a href="/" style={{ marginRight: "auto", textAlign: "start" }}>
              <img
                className={`navbarLogo ${cssModule.userNavbarLogo}`}
                src={images["mainLogo"]}
                alt=""
              />
            </a>
            <Hidden mdDown implementation="css">
              <div className={cssModule.desktop_nav}>
                {Object.entries(navbar_items).map(([key, value]) => value)}
              </div>
            </Hidden>
            <Hidden mdUp implementation="css">
              <Button onClick={handleDrawerToggle}>
                <MenuIcon style={{ color: "#445eaa" }} />
              </Button>
            </Hidden>
          </Toolbar>
          <nav>
            <Hidden mdUp implementation="js">
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                anchor="top"
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{ style: { backgroundColor: "transparent" } }} // Make Drawer background transparent
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </ThemeProvider>
      </AppBar>
    </div>
  );
};

export default UserNavBar;
