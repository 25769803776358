import { oscillation } from '../game/utils';
import { BaseAnimation } from './base';

type Config = {
  speed: number;
  distance: number;
};

export class OscillateAnimation extends BaseAnimation<Config> {
  run() {
    this.sprite.velocity.set(oscillation(-1 * this.config.distance, Math.abs(this.config.distance), this.sprite.p.frameCount / this.config.speed), 0);
  }
}
