import React, { useEffect, useState } from "react";

import Campaigns from "../../Components/campaigns/Campaigns";

import ApiService from "../../Services/apiService";
import useGetCookieHook from "../../Hooks/useGetCookieHook";

const UserCampaignsPage = () => {
  const [currentCampaign, setCurrentCampaign] = useState();
  const getCookieHook = useGetCookieHook()
  const userCookie = getCookieHook("user")  
  const role = userCookie?.role
  const apiService = new ApiService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [campaignInfoResp, err] = await apiService.get({
          path: "/get_campaigns",
          dataType: "admin",
        });

        // Check if the response contains data and there are campaigns
        if (campaignInfoResp?.data?.campaigns?.length) {
          // For Now until we have UI to show all campaigns, select the second campaign
          setCurrentCampaign(campaignInfoResp.data.campaigns[0]);
        } else {
          console.error("No campaigns found in the response");
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {currentCampaign && <Campaigns campaign={currentCampaign} role={role} />}
    </>
  );
};

export default UserCampaignsPage;
