import Coin00 from './Coin_0000000.png';
import Coin01 from './Coin_0000001.png';
import Coin02 from './Coin_0000002.png';
import Coin03 from './Coin_0000003.png';
import Coin04 from './Coin_0000004.png';

export const coin = {
  rotate: {
    sprites: [Coin00, Coin01, Coin02, Coin03],
    loop: true,
  },
  idle: {
    sprites: [Coin04],
    loop: false,
  },
};
