import React, { useMemo, useState, useEffect, useRef } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import styles from "./missionsTable.module.scss";
import PropTypes from "prop-types";

import EditBtn from "./components/EditBtn";
import TableDropdownBtn from "./components/TableDropdownBtn";
import CustomBtnComponent from "./components/CustomBtnComponent";
import Tooltip from "./components/Tooltip";
import TableHead from "./components/TableHead";
import TableBody from "./components/TableBody";

export const MissionsTable = ({
  title,
  verify,
  editable,
  route = "",
  onSaveHandler,
  checkbox,
  data = [],
  tableSetup = [],
  clickHandler,
  buttonHandler,
  customBtn,
  tooltip,
  tooltipSelected,
  setSelectedProp,
}) => {
  const [updateDom, setUpdateDom] = useState({ id: null, count: 0 });
  const [currentData, setCurrentData] = useState(data);
  const [selected, setSelected] = useState([]);
  const [rowEdit, setRowEdit] = useState([]);
  const hasValidationError = useRef([]);
  const [editingBtnValue, setEditingBtnValue] = useState({});
  const noneTableContingenciesRef = useRef(null);

  useEffect(() => {
    if (setSelectedProp) {
      setSelectedProp(selected);
    }
  }, [selected]);

  const editBtnClickHandler = (rowId) => {
    if (hasValidationError.current.some((id) => id === rowId)) {
      return;
    } else {
      setEditingBtnValue((prev) => {
        const updated = { ...prev };
        if (updated[rowId]) {
          updated[rowId].btnValue = !updated[rowId].btnValue;
        } else {
          updated[rowId] = { btnValue: true, validationError: false };
        }
        return { ...updated };
      });
    }
    setUpdateDom((prev) => ({
      id: rowId,
      count: prev.count + 1,
    }));
  };

  const checkValidation = (value, pattern, rowId) => {
    if (pattern) {
      if (pattern.test(value)) {
        if (hasValidationError.current.some((id) => id === rowId)) {
          hasValidationError.current = hasValidationError.current.filter(
            (id) => id !== rowId
          );
          return true;
        }
      } else {
        hasValidationError.current = [...hasValidationError.current, rowId];
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setCurrentData(data);
    if (selected.length > 0) {
      setSelected((prev) => {
        return prev.filter((id) => {
          return data.some((row) => row.id === id);
        });
      });
    }
  }, [data]);

  const updateDataFields = (row, accessorKey, difference) => {
    if (!row.rawData) {
      row[accessorKey] = +row[accessorKey] + difference;
    } else {
      row.rawData[accessorKey].value =
        +row.rawData[accessorKey].value + difference;
    }
  };

  const updateDomHandler = (
    rowId,
    accessorKey,
    difference,
    path,
    arrayFilters
  ) => {
    setCurrentData((prev) => {
      return prev.map((row) => {
        if (row.id === rowId) {
          updateDataFields(row, accessorKey, difference);
          if (
            row.noneTableContingencies &&
            row.noneTableContingencies[accessorKey]
          ) {
            noneTableContingenciesRef.current =
              row.noneTableContingencies[accessorKey];
          }
          if (row.contingencies && row.contingencies[accessorKey]) {
            row.contingencies[accessorKey].forEach((contingency) => {
              updateDataFields(row, contingency, difference);
            });
          }
          return row;
        } else {
          return row;
        }
      });
    });

    // Using a timeout to ensure the state update has finished before running onSaveHandler
    setTimeout(() => {
      onSaveHandler({
        userId: rowId,
        route: route,
        path: path,
        newValue: difference,
        arrayFilters: arrayFilters,
        noneTableContingencies: noneTableContingenciesRef.current,
      });
    }, 0);
  };

  const selectSingle = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const selectAll = () => {
    if (selected.length === rows.length) {
      setSelected([]);
    } else {
      setSelected(rows.map((row) => row.original.id || row.original._id));
    }
  };

  const columns = useMemo(() => {
    let columns = [
      ...tableSetup,
      // { accessorKey: "fname", header: "First Name" },
      // { accessorKey: "lname", header: "Last Name" },
      // { accessorKey: "mission", header: "Mission" },
    ];
    if (checkbox) {
      columns = [
        {
          id: "choose",
          header: (
            <input
              type="checkbox"
              checked={data.length > 0 && selected.length === data.length}
              readOnly
              onClick={selectAll}
            />
          ),
          cell: (row) => (
            <input
              type="checkbox"
              checked={selected.includes(
                row.row.original._id || row.row.original.id
              )}
              readOnly
              onClick={() =>
                selectSingle(row.row.original._id || row.row.original.id)
              }
            />
          ),
        },
        ...columns,
      ];
    }
    if (verify) {
      columns.push({
        id: "verify",
        header: "Verify",
        cell: ({ row }) =>
          row.original.verified ? (
            "Verified"
          ) : (
            <TableDropdownBtn
              ids={[row.original.id]}
              buttonHandler={buttonHandler}
              selectSingle={selectSingle}
            />
          ),
      });
    }

    if (editable) {
      columns.push({
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <EditBtn
            rowId={row}
            hasValidationError={hasValidationError}
            rowEdit={rowEdit}
            editBtnClickHandler={editBtnClickHandler}
            editingBtnValue={editingBtnValue}
          />
        ),
      });
    }

    if (customBtn) {
      columns.push({
        id: "customBtn",
        header: customBtn.header,
        cell: ({ row }) => (
          <CustomBtnComponent
            text={customBtn.text}
            clickHandler={customBtn.clickHandler}
            rowId={[row.original._id ? row.original._id : row.original.id]}
          />
        ),
      });
    }
    return columns;
  }, [verify, selected, editingBtnValue]);

  const table = useReactTable({
    data: currentData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const containerRef = React.useRef(null);

  return (
    <div className={styles.missionsTable}>
      {
        <Tooltip
          title={title}
          verify={verify}
          selected={selected}
          tooltip={tooltip}
          tooltipSelected={tooltipSelected}
          buttonHandler={buttonHandler}
          selectSingle={selectSingle}
        />
      }
      <div className={styles.tableWrapper} ref={containerRef}>
        <table>
          <TableHead table={table} />
          <TableBody
            rows={rows}
            clickHandler={clickHandler}
            selected={selected}
            editable={editable}
            updateDom={updateDom}
            updateDomHandler={updateDomHandler}
            setRowEdit={setRowEdit}
            editingBtnValue={editingBtnValue}
            checkValidation={checkValidation}
          />
        </table>
      </div>
    </div>
  );
};

MissionsTable.propTypes = {
  title: PropTypes.string.isRequired,
  verify: PropTypes.bool,
  editable: PropTypes.bool,
  route: PropTypes.string,
  onSaveHandler: PropTypes.func,
  checkbox: PropTypes.bool,
  data: PropTypes.array.isRequired,
  tableSetup: PropTypes.arrayOf(
    PropTypes.shape({
      accessorKey: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
    })
  ).isRequired,
  clickHandler: PropTypes.func,
  buttonHandler: PropTypes.func,
  customBtn: PropTypes.shape({
    text: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired, // Ensure this is a function if needed
  }),
  tooltip: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    action: PropTypes.func,
  }),
  tooltipSelected: PropTypes.shape({}),
  setSelectedProp: PropTypes.func,
};

// Default props can also be set here
MissionsTable.defaultProps = {
  verify: null,
  editable: null,
  route: "",
  onSaveHandler: null,
  checkbox: null,
  clickHandler: null,
  buttonHandler: null,
  customBtn: null,
  tooltip: null,
  tooltipSelected: null,
  setSelectedProp: null,
};
