import React, { useCallback, useEffect, useRef, useState } from "react";
import p5 from "p5";
import {
  GAME_CONFIG,
  GameConfig,
  ToolConfig,
  ToolConfigWithId,
  WorldConfig,
} from "./game/game-config";
import { Game } from "./game/game";
import { Tools } from "./components/Tools";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider, useDrop } from "react-dnd";
import { DialogProps } from "./components/info-modal";
import { Controls } from "./components/Controls";
import DialogTemplate from "../../../Components/DialogTemplate";
import UserNavBar from "../../Components/userNavbar/UserNavBar";
import ApiService from "../../../Services/apiService";
import Loading from "../../../Components/loading/Loading";

import {
  gameBg,
  character,
  coin,
  clouds,
  ladder1,
  ladder1Path,
  bridge1,
  bridge1Path,
  ladder2,
  ladder2Path,
  bridge2,
  bridge2Path,
  rope1,
  rope1Path,
  pole,
  polePath,
  spikes,
  floatingRock,
  floatingRockPath,
  jackPot,
  ladderTool,
  bridgeTool,
  ropeTool,
  poleTool,
  groundPath,
} from "../../../assets/images/treasure-road";

const myImages: any = {
  gameBg,
  character,
  coin,
  clouds,
  ladder1,
  ladder1Path,
  bridge1,
  bridge1Path,
  ladder2,
  ladder2Path,
  bridge2,
  bridge2Path,
  rope1,
  rope1Path,
  pole,
  polePath,
  spikes,
  floatingRock,
  floatingRockPath,
  jackPot,
  ladderTool,
  bridgeTool,
  ropeTool,
  poleTool,
  groundPath,
};

function createSketch(
  config: GameConfig,
  width: number,
  height: number,
  ref: React.MutableRefObject<Game | null>
) {
  return (p: p5) => {
    let game: Game;

    p.setup = () => {
      game = new Game(p, config, width, height);
      ref.current = game;
      game.setup();
    };

    p.draw = () => {
      game.draw();
    };
  };
}

const Canvas: React.FC<CanvasProps> = React.memo(
  ({ config, width, height, ...props }) => {
    const ref = useRef(null);
    const gameInstance = useRef<Game | null>(null);

    const [, drop] = useDrop<ToolConfigWithId>({
      accept: Object.keys(config.tools),
      drop: (item, monitor) => {
        if (gameInstance.current) {
          const accept = gameInstance.current?.dropTool(item);

          if (!accept) {
            props.infoModal({
              mainText: "Wrong tool",
              subText: `The ${item.name} cannot be dropped here.`,
              closeHandler: () => props.infoModal(null),
            });
          } else {
            props.onToolDrop(item.id);
          }
        }
      },
      canDrop: (item) => {
        if (gameInstance.current) {
          gameInstance.current?.canDropTool(item);
        }
        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    useEffect(() => {
      if (ref.current) {
        const myp5 = new p5(
          createSketch(config, width, height, gameInstance),
          ref.current
        );
        return () => {
          myp5.remove();
        };
      }
    }, []);

    useEffect(() => {
      if (gameInstance.current) {
        gameInstance.current?.updateConfig(config);
      }
    }, [config]);

    useEffect(() => {
      if (gameInstance.current) {
        gameInstance.current?.resize(width, height);
      }
    }, [width, height]);
    return (
      <div className="relative overflow-hidden shadow-2xl z-10" ref={drop}>
        <div ref={ref} style={{ width, height, bottom: 0 }}></div>
      </div>
    );
  }
);

interface CanvasProps {
  config: GameConfig;
  width: number;
  height: number;
  infoModal: (props: DialogProps | null) => void;
  onToolDrop: (id: string) => void;
}

const TreasureRoadPage: React.FunctionComponent<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState({} as GameConfig);
  const [infoModal, setInfoModal] = useState<DialogProps | null>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [tools, setTools] = useState<Record<string, ToolConfig>>({
    ...config.tools,
  });

  const apiService = new ApiService();

  useEffect(() => {
    const fetchData: any = async () => {
      try {
        const [response, err] = await apiService.get({
          path: "/treasure_road",
          dataType: "user",
        });
        if (err) throw err;
        return response;
      } catch (error) {
        console.error('Error retrieving Treasure Road', error);
      }
    };
    fetchData()
      .then((res: any) => {
        const { treasureRoad, gameInventory } = res.data;
        if (res) {
          const config: GameConfig = {
            world: {
              ...GAME_CONFIG.world,
            },
            coin: {
              ...GAME_CONFIG.coin,
            },
            character: {
              ...GAME_CONFIG.character,
            },
            obstacles: GAME_CONFIG.obstacles,
            tools: gameInventory?.structures?.reduce(
              (acc: any, cur: any) => {
                const imageUrl = myImages[`${cur.name}Tool`];
                if (imageUrl) {
                  // Added this check to avoid showing invalid tools like heart
                  acc[cur.name] = {
                    ...cur,
                    image: myImages[`${cur.name}Tool`],
                  };
                }
                return acc;
              },
              {}
            ),
          };
          setConfig(config);
          setTools({ ...config.tools });
        }
      })
      .catch((e: Error) => {
        console.error("Error getting treasure road data", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const onToolDrop = useCallback((id: string) => {
    setTools((tools) => ({
      ...tools,
      [id]: {
        ...tools[id],
        quantity: tools[id].quantity - 1,
      },
    }));
  }, []);

  return (
    <div>
      <nav className="">
        <UserNavBar />
      </nav>
      {isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <DndProvider
          backend={navigator.maxTouchPoints <= 0 ? HTML5Backend : TouchBackend}
        >
          {infoModal && <DialogTemplate {...infoModal} />}
          <div className="fixed top-0 left-0 bottom-0 right-0 bg-brand flex justify-center items-center">
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-md z-10"></div>
            <Canvas
              config={config}
              width={width}
              height={height}
              infoModal={setInfoModal}
              onToolDrop={onToolDrop}
            />
            <Tools tools={tools} />
            <Controls orientation={width > height ? "landscape" : "portrait"} />
          </div>
        </DndProvider>
      )}
    </div>
  );
};

interface Props {}

export default TreasureRoadPage;
