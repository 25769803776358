import {Vector} from './utils';
import {BaseAnimation} from '../animations/base';
import {OscillateAnimation} from '../animations/oscillate.animation';
import {CoinsAnimation} from '../animations/coins.animation';
import {
  gameBg,
  character,
  coin,
  clouds,
  ladder1,
  ladder1Path,
  bridge1,
  bridge1Path,
  ladder2,
  ladder2Path,
  bridge2,
  bridge2Path,
  rope1,
  rope1Path,
  pole,
  polePath,
  spikes,
  floatingRock,
  floatingRockPath,
  jackPot,
  ladderTool,
  bridgeTool,
  ropeTool,
  poleTool,
  groundPath
} from '../../../../assets/images/treasure-road';

type ClassType<T> = new (...args: any[]) => T;

export type SpriteAnimation<T> = {
  type: ClassType<BaseAnimation<T>>;
  config: T;
  initialActive?: boolean
};

export type SpriteSheetConfig = {
  sprites: Array<string>;
  loop: boolean;
};

export interface SpriteConfig<States extends string = never> {
  id: string;
  position?: Vector;
  velocity?: Vector;
  width: number;
  height: number;
  bgImageUri: string | Record<States, SpriteSheetConfig>;
  initialState?: States;
  animations?: Record<string, SpriteAnimation<any>>;
}

export interface ObstacleConfig<States extends string = never> extends SpriteConfig<States> {
  pathImageUri?: string;
  isCompleted?: boolean;
  isDangerous?: boolean;
  requiredTools?: Array<string>;
}

export interface WorldConfig extends ObstacleConfig {
  scale: number;
  gravity: number;
}

export type CharacterState =
  'attack'
  | 'die'
  | 'dizzy'
  | 'duck'
  | 'hurt'
  | 'idle'
  | 'jetpack'
  | 'jump'
  | 'run'
  | 'walk';

export type CoinState = 'rotate' | 'idle';

export interface CharacterSpeedConfig {
  walk: number;
  jump: number;
  run: number;
  climb: number;
}

export interface CharacterConfig extends SpriteConfig<CharacterState> {
  speed: CharacterSpeedConfig;
  jumpDistance: number;
}

export interface CoinConfig extends SpriteConfig<CoinState> {
}

export interface ToolConfig {
  name: string;
  image: string;
  quantity: number;
}

export interface ToolConfigWithId extends ToolConfig {
  id: string;
}

export interface GameConfig {
  world: WorldConfig;
  character: CharacterConfig;
  coin: CoinConfig;
  obstacles: Array<ObstacleConfig>;
  tools: Record<string, ToolConfig>;
}

export const GAME_CONFIG: GameConfig = {
  world: {
    id: 'world',
    pathImageUri: groundPath,
    gravity: 0.5,
    bgImageUri: gameBg,
    width: 5000,
    height: 2146,
    scale: 1.5,
    isCompleted: true,
  },
  coin: {
    id: 'coin',
    position: {x: 0, y: 0},
    velocity: {x: 0, y: 0},
    width: 40,
    height: 40,
    initialState: 'rotate',
    bgImageUri: coin,
  },
  character: {
    id: 'player',
    bgImageUri: character,
    jumpDistance: 100,
    position: {
      x: 42,
      y: 1200,
    },
    width: 144,
    height: 144,
    initialState: 'jump',
    speed: {
      walk: 5,
      jump: 18,
      run: 10,
      climb: 1,
    },
  },
  obstacles: [
    {
      id: 'clouds',
      bgImageUri: clouds,
      position: {
        x: 40,
        y: 40,
      },
      width: 5000,
      height: 400,
      isCompleted: true,
      animations: {
        move: {
          type: OscillateAnimation,
          config: {
            speed: 100,
          },
          initialActive: true
        },
      },
    },
    {
      id: 'ladder-1',
      position: {x: 169, y: 1135},
      width: 125,
      height: 611,
      pathImageUri: ladder1Path,
      bgImageUri: ladder1,
      requiredTools: ['ladder'],
    },
    {
      id: 'bridge-1',
      position: {x: 437, y: 1604},
      width: 531,
      height: 170,
      pathImageUri: bridge1Path,
      bgImageUri: bridge1,
      requiredTools: ['bridge'],
    },
    {
      id: 'ladder-2',
      position: {x: 1753, y: 928},
      width: 156,
      height: 465,
      pathImageUri: ladder2Path,
      bgImageUri: ladder2,
      requiredTools: ['ladder'],
    },
    {
      id: 'bridge-2',
      position: {x: 1502, y: 1690},
      width: 311,
      height: 126,
      pathImageUri: bridge2Path,
      bgImageUri: bridge2,
      requiredTools: ['bridge'],
    },
    {
      id: 'rope-1',
      position: {x: 1235, y: 1250},
      width: 153,
      height: 361,
      pathImageUri: rope1Path,
      bgImageUri: rope1,
      requiredTools: ['rope'],
    },
    {
      id: 'pole',
      position: {x: 2437, y: 1523},
      width: 334,
      height: 69,
      pathImageUri: polePath,
      bgImageUri: pole,
      requiredTools: ['pole'],
    },
    {
      id: 'spikes',
      position: {x: 3263, y: 1420},
      width: 779,
      height: 153,
      bgImageUri: spikes,
      isDangerous: true,
      isCompleted: true
    },
    {
      id: 'floating_rock',
      position: {x: 3338, y: 1238},
      width: 532,
      height: 200,
      bgImageUri: floatingRock,
      pathImageUri: floatingRockPath,
      isCompleted: true,
      animations: {
        move: {
          type: OscillateAnimation,
          config: {
            distance: 7,
            speed: 10,
          },
          initialActive: true
        },
      },
    },
    {
      id: 'jackpot',
      position: {x: 4631, y: 1160},
      width: 208,
      height: 183,
      bgImageUri: jackPot,
      isCompleted: true,
      animations: {
        coins: {
          type: CoinsAnimation,
          config: {
            count: 50,
          },
        },
      },
    },
  ],
  tools: {
    ladder: {
      name: 'Ladder',
      quantity: 2,
      image: ladderTool,
    },
    bridge: {
      name: 'Bridge',
      quantity: 2,
      image: bridgeTool,
    },
    rope: {
      name: 'Rope',
      quantity: 1,
      image: ropeTool,
    },
    pole: {
      name: 'Pole',
      quantity: 1,
      image: poleTool,
    },
  },
};
