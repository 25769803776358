import { useCallback } from "react";
import { SetState } from "../../../../types";
import { capitalizeWords } from "../../../../Util/utils";

type SetDialogType = null | {
  open: boolean;
  classes: string;
  mainText: string;
  mainTextClass: string;
  subText: string;
  subTextClass: string;
  duration: string;
  closeHandler: () => void;
};

const useMaxedOutDialog = () => {
  const maxedOutDialog = useCallback(
    (setDialog: SetState<SetDialogType>, missionsName: string) => {
      missionsName = capitalizeWords(missionsName);
      setDialog({
        open: true,
        classes: "mission_modal_container",
        mainText: `${missionsName} Missions Maxed Out!`,
        mainTextClass: "mission_modal_main_text",
        subText:
          "You completed this mission to the max!\nCome back tommorrow to do it again!",
        subTextClass: "mission_modal_sub_text",
        duration: "1000ms",
        closeHandler: () => setDialog(null),
      });
    },
    []
  );
  return maxedOutDialog;
};

export default useMaxedOutDialog;
