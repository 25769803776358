import { GameConfig } from './game-config';
import p5 from 'p5';
import { Obstacle } from './obstacle';

export class GameContext {
  public readonly obstacles: Array<Obstacle> = [];
  public readonly characterPosition: p5.Vector = this.p.createVector(this.config.character.position?.x, this.config.character.position?.y);
  public readonly worldOffset = this.p.createVector(0, 0);
  private readonly mouseMoveListeners = new Set<Function>();
  private readonly keyPressListeners = new Set<Function>();
  private readonly keyReleaseListeners = new Set<Function>();
  private readonly mouseDragListeners = new Set<Function>();

  constructor(
    public p: p5,
    public config: GameConfig
  ) {}

  public get gravity() {
    return this.p.createVector(0, this.config.world.gravity);
  }

  public addObstacle(obstacle: Obstacle) {
    this.obstacles.push(obstacle);
  }

  public updateCharacterPosition(position: p5.Vector) {
    this.characterPosition.set(position);

    const viewPort = this.calculateViewport();

    let offsetX = 0;
    let offsetY = this.config.world.height - viewPort.height;

    if (position.x > viewPort.width / 2) {
      offsetX = position.x - viewPort.width / 2;
    }

    if (position.y < this.config.world.height - this.p.height / 2) {
      offsetY = position.y - this.p.height / 2;
    }

    if (offsetY < 0) offsetY = 0;
    if (offsetX < 0) offsetX = 0;
    if (offsetY > this.config.world.height - viewPort.height) offsetY = this.config.world.height - viewPort.height;
    if (offsetX > this.config.world.width - viewPort.width) offsetX = this.config.world.width - viewPort.width;

    this.worldOffset.set(-offsetX - viewPort.width / 2, -offsetY - viewPort.height / 2);
  }

  public calculateViewport() {
    const height = this.config.world.scale * this.p.height;
    const width = this.config.world.scale * this.p.width;

    return { width, height };
  }

  public get worldScale() {
    return 1 / this.config.world.scale;
  }

  public getWorld() {
    return this.obstacles.find((obstacle) => obstacle.getId() === 'world');
  }

  public addMouseMoveListener(listener: Function) {
    this.mouseMoveListeners.add(listener);
  }

  public addKeyPressListener(listener: Function) {
    this.keyPressListeners.add(listener);
  }

  public addKeyReleaseListener(listener: Function) {
    this.keyReleaseListeners.add(listener);
  }

  public addMouseDragListener(listener: Function) {
    this.mouseDragListeners.add(listener);
  }

  public mouseMoved(e: any) {
    // alert(JSON.stringify({ x: e.offsetX, y: e.offsetY}))
    this.mouseMoveListeners.forEach((listener) => listener(e));
  }

  public keyPressed(e: any) {
    this.keyPressListeners.forEach((listener) => listener(e));
  }

  public keyReleased(e: any) {
    this.keyReleaseListeners.forEach((listener) => listener(e));
  }

  public mouseDragged(e: any) {
    this.mouseDragListeners.forEach((listener) => listener(e));
  }
}
