import React, {useCallback} from 'react';
import {IconArrowDown, IconArrowLeft, IconArrowRight, IconArrowUp} from '@tabler/icons-react';
import clsx from 'clsx';
import {Button} from '../../../../Components/button/button';

const keyCodes = {
  up: 38,
  down: 40,
  left: 37,
  right: 39,
};

export const Controls: React.FC<ControlsProps> = (props) => {
  const fireKeyEvent = useCallback((key: keyof typeof keyCodes, eventType: string) => {
    const event = new KeyboardEvent(eventType, {
      key: `Arrow${key.charAt(0).toUpperCase() + key.slice(1)}`,
      code: `Arrow${key.charAt(0).toUpperCase() + key.slice(1)}`,
      keyCode: keyCodes[key],
      bubbles: true,
      cancelable: true,
      repeat: false,
    });

    document.body.dispatchEvent(event);
  }, []);

  if (navigator.maxTouchPoints <= 0) {
    return null;
  }

  return (
    <div className="absolute bottom-0 left-0 w-full z-20 p-4">
      <div
        className={clsx('flex flex-col', {
          ['items-end']: props.orientation === 'landscape',
          ['items-center']: props.orientation === 'portrait',
        })}
      >
        <div className="flex flex-col items-center">
          <Button onTouchStart={() => fireKeyEvent('up', 'keydown')} onTouchEnd={() => fireKeyEvent('up', 'keyup')}>
            <IconArrowUp />
          </Button>
          <div className="flex gap-20">
            <Button onTouchStart={() => fireKeyEvent('left', 'keydown')} onTouchEnd={() => fireKeyEvent('left', 'keyup')}>
              <IconArrowLeft />
            </Button>
            <Button onTouchStart={() => fireKeyEvent('right', 'keydown')} onTouchEnd={() => fireKeyEvent('right', 'keyup')}>
              <IconArrowRight />
            </Button>
          </div>
          <Button onTouchStart={() => fireKeyEvent('down', 'keydown')} onTouchEnd={() => fireKeyEvent('down', 'keyup')}>
            <IconArrowDown />
          </Button>
        </div>
      </div>
    </div>
  );
};

export interface ControlsProps {
  orientation: 'portrait' | 'landscape';
}
