import { useCallback } from "react";

import ApiService from "../Services/apiServiceT";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

type logoutResp = {
  message: string;
};

const useGetCookieHook = () => {
  const navigate = useNavigate();
  const apiService = new ApiService();

  const logoutHandler = async () => {
    try {
      await apiService.get<logoutResp>({
        path: "/",
        dataType: "logout",
      });
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const getCookieHook = useCallback((cookieName: string) => {
    const cookie = Cookies.get(cookieName);
    if (!cookie) {
      logoutHandler();
    } else {
      return JSON.parse(cookie);
    }
  }, []);
  return getCookieHook;
};

export default useGetCookieHook;
