import {BaseAnimation} from './base';
import {Sprite} from '../game/sprite';
import {GameContext} from '../game/game-context';
import p5 from 'p5';
import {findIntersection} from '../game/utils';
import {CoinConfig, CoinState} from '../game/game-config';

type Config = {
  count: number;
  gravity?: number;
};

class Coin extends Sprite<CoinConfig, CoinState> {
  public opacity = 255;

  constructor(
    context: GameContext,
    position: p5.Vector,
    velocity: p5.Vector,
    private parent: Sprite<any, any>
  ) {
    super(context, {
      ...context.config.coin,
      position,
      velocity,
    });
  }

  draw() {
    this.checkGround();
    super.draw();
  }

  private checkGround() {
    const world = this.context.getWorld();
    if (world) {
      for (let block of world.getBlocks()) {
        const { line, reset } = this.getPerpendicular(world);
        const intersection = findIntersection(
          {
            start: this.position.copy().add(this.parent.position).add(line.start.x, line.start.y),
            end: this.position.copy().add(this.parent.position).add(line.end.x, line.end.y),
          },
          block
        );

        if (intersection && this.velocity.y > 0) {
          this.velocity.set(0, 0);
          this.state = 'idle';
        }
      }
    }
  }
}

export class CoinsAnimation extends BaseAnimation<Config> {
  private coinPositions: Array<Coin> = [];

  initialize() {
    super.initialize();
    this.createCoins();
  }

  run() {
    for (let i = this.coinPositions.length - 1; i >= 0; i--) {
      const coin = this.coinPositions[i];
      this.p.push();
      this.p.tint(255, coin.opacity);
      this.p.noStroke();

      if (coin.state !== 'idle') {
        coin.velocity.add(this.p.createVector(0, 0.3));
      }

      coin.draw();

      this.p.pop();

      if (this.coinPositions.length === 0) {
        this.stop();
      }
    }
  }

  private createCoins() {
    this.coinPositions = Array(this.config.count)
      .fill(0)
      .map(() => {
        return new Coin(
          this.sprite.context,
          this.p.createVector(this.p.random(this.sprite.width * 0.45, this.sprite.width * 0.55), 0),
          this.p.createVector(this.p.random(-2, 2), this.p.random(-25, -18)),
          this.sprite
        );
      });
  }
}
