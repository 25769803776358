import p5 from 'p5';
import { Sprite } from '../game/sprite';

export abstract class BaseAnimation<T> {
  protected p: p5 = this.sprite.p;

  public constructor(
    protected config: T,
    protected sprite: Sprite<any, any>,
    protected active = true
  ) {
    this.initialize();
  }

  public stop() {
    this.active = false;
    this.initialize();
  }

  public pause() {
    this.active = false;
  }

  public start() {
    this.active = true;
  }

  public initialize(): void {}

  abstract run(): void;

  public isActive() {
    return this.active;
  }
}
