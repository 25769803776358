import {Sprite} from './sprite';
import {CharacterConfig, CharacterState} from './game-config';
import {GameContext} from './game-context';
import {Obstacle} from './obstacle';
import {Block, findIntersection, getGradient, Vector} from './utils';
import p5 from 'p5';

export class Character extends Sprite<CharacterConfig, CharacterState> {
  private obstacle: Obstacle | null = null;
  private currentBlock: Block | null = null;
  private previousBlock: Block | null = null;
  private previousObstacle: Obstacle | null = null;

  private foundJackpot = false;

  constructor(public context: GameContext) {
    super(context, context.config.character);
  }

  setup() {
    super.setup();
    this.velocity.add(this.context.gravity);

    this.context.addKeyPressListener((e: any) => {
      if (e.key === 'ArrowRight') {
        this.moveRight();
      } else if (e.key === 'ArrowLeft') {
        this.moveLeft();
      } else if (e.key === 'ArrowUp' || e.key === ' ') {
        this.moveUp();
      } else if (e.key === 'ArrowDown') {
        this.moveDown();
      }
    });

    this.context.addKeyReleaseListener((e: any) => {
      if (this.state !== 'jump') {
        this.state = 'idle';
        this.velocity.set(0, 0);
      }
    });
  }

  private moveUp() {
    if (this.currentBlock) {
      if (this.isRope()) {
        const motion = this.getMotionAlongBlock(this.currentBlock, Math.abs(this.config.speed.climb));
        this.velocity.add(motion.x, -Math.abs(motion.y));
      } else {
        this.state = 'jump';
        const motion = this.getPerpendicularMotion(this.currentBlock, this.config.speed.jump);
        const checkJump = this.checkNextJumpBlock(motion);

        this.velocity.set(checkJump);
      }
    }
  }

  private isRope() {
    return this.obstacle?.currentTool?.id === 'rope';
  }

  private moveDown() {
    if (this.currentBlock) {
      if (this.isRope()) {
        const motion = this.getMotionAlongBlock(this.currentBlock, Math.abs(this.config.speed.climb));
        this.velocity.add(motion.x, Math.abs(motion.y));
      } else {
        const { block } = this.findClosestBlock(this.context.gravity, this.config.jumpDistance);
        if (block) {
          this.state = 'jump';
          this.setBlock(null, null);
          this.position.add(0, Math.abs(this.config.speed.jump));
        }
      }
    }
  }

  private moveRight() {
    if (this.state !== 'jump') {
      this.state = 'walk';
    }

    if (this.currentBlock) {
      if (this.isRope()) {
        this.state = 'jump';
        this.velocity.set(Math.abs(this.config.speed.walk), -Math.abs(this.config.speed.walk));
      } else {
        const motion = this.getMotionAlongBlock(this.currentBlock, this.config.speed.walk);
        this.velocity.add(motion);
      }
    } else {
      if(this.velocity.x === 0) {
        this.velocity.add(this.p.createVector(this.config.speed.walk, 0));
      }
    }
  }

  private moveLeft() {
    if (this.state !== 'jump') {
      this.state = 'walk';
    }
    if (this.currentBlock) {
      if (this.isRope()) {
        this.state = 'jump';
        this.velocity.set(-Math.abs(this.config.speed.walk), -Math.abs(this.config.speed.walk));
      } else {
        const motion = this.getMotionAlongBlock(this.currentBlock, -this.config.speed.walk);
        this.velocity.add(motion);
      }
    } else {
      if(this.velocity.x === 0) {
        this.velocity.add(this.p.createVector(-this.config.speed.walk, 0));
      }
    }
  }

  private checkCollision() {
    this.checkGround();

    if (!this.currentBlock) {
      this.velocity.add(this.context.gravity);
    }
  }

  private checkGround() {
    let found = false;
    for (let obstacle of this.context.obstacles) {
      if (!obstacle.isCompleted) continue;

      for (let block of obstacle.getBlocks()) {
        const { line, reset } = this.getPerpendicular(obstacle);
        const intersection = findIntersection(
          {
            start: this.position.copy().add(line.start.x, line.start.y),
            end: this.position.copy().add(line.end.x, line.end.y),
          },
          block
        );

        if (intersection) {
          if (!this.currentBlock) {
            this.velocity.set(0, 0);
            this.state = 'idle';
          }

          this.setBlock(block, obstacle);
          this.position.set(intersection.x - reset.x, intersection.y - reset.y);
          this.position.add(obstacle.velocity);

          this.checkDangerousObstacles();

          found = true;
        }
      }
    }

    if (!found) {
      this.setBlock(null, null);
    }
  }

  private checkDangerousObstacles() {
    for (let obstacle of this.context.obstacles) {
      if (obstacle.isDangerous() && obstacle.isCharacterWithin(this, -5)) {
        this.state = 'die';
        this.velocity.set(0, 0);
        break;
      }
    }
  }

  draw() {
    if (!this.isDead()) {
      this.checkCollision();
      this.checkNearObstacle();
      this.checkFall();
      this.checkWonJackpot();
    }
    super.draw();
    this.context.updateCharacterPosition(this.position.copy());
  }

  private findClosestBlock(velocity: p5.Vector, radius = 1000) {
    let closestBlock: Block | null = null;
    let minDistance = radius;
    let intersection: Vector | null = null;

    const line = {
      start: this.position
        .copy()
        .add(this.width / 2, this.height)
        .sub(0, 2),
      end: this.position
        .copy()
        .add(this.width / 2, this.height)
        .add(velocity.copy().normalize().mult(radius)),
    };

    for (let obstacle of this.context.obstacles) {
      if (!obstacle.isCompleted) continue;

      for (let block of obstacle.getBlocks()) {
        intersection = findIntersection(line, block);

        if (!intersection) continue;

        const distance = this.p.dist(line.start.x, line.start.y, intersection.x, intersection.y);

        if (distance < minDistance && block.id !== this.currentBlock?.id) {
          minDistance = distance;
          closestBlock = block;
        }
      }
    }

    return {
      block: closestBlock,
      intersection,
      distance: minDistance,
    };
  }

  private checkNextJumpBlock(velocity: p5.Vector) {
    const { block, distance } = this.findClosestBlock(velocity, this.config.jumpDistance);

    if (block) {
      const vy = Math.sqrt(2 * this.context.gravity.y * distance);

      return this.p.createVector(velocity.x, -vy);
    }

    return velocity;
  }

  private checkNearObstacle() {
    for (let obstacle of this.context.obstacles) {
      if (obstacle.checkBlockingCharacter(this)) {
        break;
      }
    }
  }

  private getMotionAlongBlock(block: Block, velocity: number) {
    const { slope } = getGradient(block);

    if (slope === Infinity) {
      return this.p.createVector(0, velocity);
    }

    return this.p.createVector(velocity, slope * velocity);
  }

  private getPerpendicularMotion(block: Block, velocity: number) {
    velocity = -Math.abs(velocity);

    const { dx } = getGradient(block);

    if (dx < 0.5) {
      return this.p.createVector(velocity, 0);
    }

    return this.p.createVector(0, velocity);
  }

  private setBlock(block: Block | null, obstacle: Obstacle | null) {
    if (block) {
      this.previousBlock = block;
    }

    if (obstacle) {
      this.previousObstacle = obstacle;
    }

    this.currentBlock = block;
    this.obstacle = obstacle;
  }

  private checkFall() {
    if (this.position.y > this.context.config.world.height) {
      this.resurrect();
    }
  }

  private getClosestEdge(block: Block) {
    const endDistance = this.p.dist(this.position.x, block.end.y, block.end.x, block.end.y);
    const startDistance = this.p.dist(this.position.x, block.start.y, block.start.x, block.start.y);

    return endDistance < startDistance ? block.end : block.start;
  }

  public isDead() {
    return this.state === 'die';
  }

  public isWon() {
    return this.foundJackpot;
  }

  private checkWonJackpot() {
    for (let obstacle of this.context.obstacles) {
      if (obstacle.getId() === 'jackpot' && obstacle.isCharacterWithin(this, 10)) {
        obstacle.startAnimation('coins');
        this.foundJackpot = true;
        break;
      }
    }
  }

  public resurrect() {
    if (this.previousBlock) {
      const distance = 50;

      const edge = this.getClosestEdge(this.previousBlock);
      if (edge === this.previousBlock.start) {
        this.position.set(edge.x + distance + this.width, edge.y - distance - this.height);
      } else {
        this.position.set(edge.x - distance - this.width, edge.y - distance - this.height);
      }

      this.state = 'idle';
      this.velocity.set(0, 0);
      this.setBlock(this.previousBlock, this.previousObstacle);
    }
  }
}
