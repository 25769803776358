import React from "react";
import { RenderableContent } from "../../types";

import mainLogo from "../../assets/images/website/supermelogo.png";
import styles from "./printTemplate.module.scss";

type Props = {
  contentToPrint: RenderableContent;
};

const PrintTemplate = React.forwardRef(
  ({ contentToPrint }: Props, ref: any) => (
    <div className={styles.mainContainer} ref={ref}>
      <div className={styles.innerContainer}>
        <div className={styles.printLogoContainer}>
          {" "}
          <img className={styles.printLogoImg} src={mainLogo} alt="" />{" "}
        </div>
        <div>{contentToPrint}</div>
      </div>
    </div>
  )
);

export default PrintTemplate;
