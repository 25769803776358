import { useEffect } from "react";

import { Data } from "../types";

import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";

import useUpdateData from "../hooks/useUpdateData";
import { SetState } from "../../../../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
};

const TaskOptionStep = ({ data, setData }: Props) => {
  const updateData = useUpdateData();

  useEffect(() => {
    if (data.taskOption.value[0] === "image") {
      setData((prev) => ({
        ...prev,
        taskOption: {
          ...prev.taskOption,
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        taskOption: {
          ...prev.taskOption,
        },
      }));
    }
  }, [data.taskOption.value]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateData(data, e.target.value, "taskOption", "", setData, [
      { name: "", url: "" },
    ]);
  };
  const options = [
    <SelectInputOption
      key={"choose"}
      option={{ name: "Select An Option Type", value: "choose" }}
    />,
    <SelectInputOption
      key={"message"}
      option={{ name: "Fill-In", value: "message" }}
    />,
    <SelectInputOption
      key={"image"}
      option={{ name: "Multiple Choice", value: "image" }}
    />,
  ];

  const mainText = "Configure Option Type";
  const subText =
    "Select an option for how the student will fill in his completed task";

  return (
    <CreatorDialogTemplate
      name="taskOption"
      data={data}
      setData={setData}
      mainText={mainText}
      subText={subText}
      selectField={[{ options, handleChange }]}
    />
  );
};

export default TaskOptionStep;
