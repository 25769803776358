import { RenderableContent } from "../../../types";
import Dialog from "../dialog/Dialog";
import styles from "./actionSuccessStatus.module.scss";

type Props = {
  message: RenderableContent;
  customContent?: RenderableContent;
  closeHandler?: () => void;
};

const ActionSuccessStatus = ({
  message,
  customContent,
  closeHandler,
}: Props) => {
  let closeBtn;
  if (closeHandler) {
    closeBtn = (
      <button
        className={`${styles.btn} btn ${styles.continueBtn}`}
        onClick={() => closeHandler()}
      >
        Close
      </button>
    );
  }

  const content = (
    <>
      <div
        className={
          customContent ? styles.scrollContent : styles.noneScrollContent
        }
      >
        <div className={`${styles.mainText} text-center`}>{message}</div>

        {customContent && closeHandler && (
          <>
            {customContent}
            <div className={styles.btnContainer}>{closeBtn}</div>
          </>
        )}
      </div>
      {!customContent && closeHandler && closeBtn}
    </>
  );
  return <Dialog content={content} />;
};

export default ActionSuccessStatus;
