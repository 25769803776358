import { ReactNode, useEffect, useState } from "react";
import {
  Data,
  GameboardNames,
  CurrentCategories,
  CreatorGameboardConfig,
  StepsReference,
  StepDetails,
  CreateProcess,
} from "../types";

import styles from "../creatorPage.module.scss";

import useUpdateCreatorDialog from "../hooks/useUpdateCreatorDialog";
import { Mission, RenderableContent } from "../../../../types";
import TaskStep from "./TaskStep";
import CategoryStep from "./CategoryStep";
import TaskOptionStep from "./TaskOptionStep";
import TaskCompletedStep from "./TaskCompletedStep";
import AddToGame from "./AddToGame";
import CreateMissionSummary from "./CreateMissionSummary";
import CategoryIconStep from "./CategoryIconStep";
import OptionsIconsStep from "./OptionsIconsStep";
import CreateProcessSelection from "./CreateProcessSelection";
import useCreateMissionDataDefaults from "../hooks/useCreateMissionDataDefaults";

type Props = {
  missions: Mission[];
  gameboardConfigs: CreatorGameboardConfig[];
  addMissionHandler: (
    mission: Omit<Mission, "_id">,
    addToGame: string,
    createProcess: string
  ) => Promise<RenderableContent>;
  currentCategories: CurrentCategories;
  currentRewardTypes: string[];
};

const CreateMission = ({
  gameboardConfigs,
  addMissionHandler,
  currentCategories,
  currentRewardTypes,
}: Props) => {
  const updateCreatorDialog = useUpdateCreatorDialog();
  const createMissionDataDefaults = useCreateMissionDataDefaults();
  const [currentStepNum, setCurrentStepNum] = useState(0);
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [process, setProcess] = useState<CreateProcess>();
  const [steps, setSteps] = useState<StepDetails | null>(null);
  const [data, setData] = useState<Data>({});

  const stepsReferenceObj: StepsReference = {
    quickCreate: {
      steps: [CreateProcessSelection, OptionsIconsStep, CreateMissionSummary],
      stepDataKeys: [
        "createProcessSelection",
        "optionsIcons",
        "createMissionSummary",
      ],
    },
    advanceCreate: {
      steps: [
        CreateProcessSelection,
        CategoryStep,
        CategoryIconStep,
        TaskStep,
        TaskOptionStep,
        OptionsIconsStep,
        TaskCompletedStep,
        AddToGame,
        CreateMissionSummary,
      ],
      stepDataKeys: [
        "createProcessSelection",
        "category",
        "categoryIcon",
        "task",
        "taskOption",
        "optionsIcons",
        "taskCompleted",
        "addToGame",
        "createMissionSummary",
      ],
    },
  };

  useEffect(() => {
    setDialogContent(<CreateProcessSelection setProcess={setProcess} />);
    createMissionDataDefaults({ process, setData });
  }, []);

  useEffect(() => {
    if (process) {
      const newSteps = stepsReferenceObj[process];
      setSteps({ steps: newSteps.steps, stepDataKeys: newSteps.stepDataKeys });
      setCurrentStepNum((prev) => prev + 1);
      createMissionDataDefaults({ process, setData });
    }
  }, [process]);

  useEffect(() => {
    if (!steps) return;
    if (currentStepNum === 0) {
      setProcess(undefined);
      setDialogContent(<CreateProcessSelection setProcess={setProcess} />);
      return;
    }

    const stepDataKeys = steps.stepDataKeys;
    const CurrentStepComponent = steps.steps[currentStepNum];
    const currentStepJsx = (
      <CurrentStepComponent
        data={data}
        setData={setData}
        currentCategories={currentCategories}
        setCurrentStepNum={setCurrentStepNum}
        currentRewardTypes={currentRewardTypes}
        gameboardConfigs={gameboardConfigs}
      />
    );

    updateCreatorDialog(
      stepDataKeys[currentStepNum],
      currentStepJsx,
      currentStepNum,
      stepDataKeys.length,
      setCurrentStepNum,
      setDialogContent,
      data,
      setData,
      addMissionHandler
    );
  }, [data, currentStepNum, steps]);

  return dialogContent || null;
};

export default CreateMission;
