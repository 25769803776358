import {BaseAnimation} from './base';
import p5 from 'p5';
import star from '../../../../assets/images/treasure-road/star.png';

type Config = {
  count: number;
};

export class StarsAnimation extends BaseAnimation<Config> {
  private starImage: p5.Image | null = null;
  private starPositions: Array<{ position: p5.Vector; velocity: p5.Vector; opacity: 255 }> = [];

  initialize() {
    super.initialize();
    if (!this.starImage) {
      this.sprite.p.loadImage(star, (img) => {
        this.starImage = img;
      });
    }
    this.createStars();
  }

  run() {
    if (this.starImage) {
      for (let i = this.starPositions.length - 1; i >= 0; i--) {
        const star = this.starPositions[i];
        this.p.push();
        this.p.tint(255, star.opacity);
        this.p.noStroke();

        this.p.image(this.starImage, star.position.x, star.position.y, 10, 10);
        star.position.add(star.velocity);
        star.opacity -= 2;

        this.p.pop();

        if (star.opacity < 0) {
          this.starPositions.splice(i, 1);
        }

        if (this.starPositions.length === 0) {
          this.stop();
        }
      }
    }
  }

  private createStars() {
    this.starPositions = Array(this.config.count)
      .fill(0)
      .map(() => {
        return {
          position: this.p.createVector(this.p.random(0, this.sprite.width), this.p.random(0, this.sprite.height)),
          velocity: this.p.createVector(this.p.random(-2, 2), this.p.random(-2, 2)),
          opacity: 255,
        };
      });
  }
}
