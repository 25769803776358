import { World } from './world';
import { Character } from './character';
import { GameContext } from './game-context';
import { GameConfig, ToolConfigWithId } from './game-config';
import p5 from 'p5';
import { Obstacle } from './obstacle';

export class Game {
  private readonly context = new GameContext(this.p, this.config);
  private readonly world = new World(this.context);
  private readonly character = new Character(this.context);
  private readonly obstacles: Array<Obstacle> = [];
  constructor(
    private p: p5,
    private config: GameConfig,
    private canvasWidth: number,
    private canvasHeight: number
  ) {
    this.config.obstacles.forEach((obstacleConfig) => {
      const obstacle = new Obstacle(this.context, obstacleConfig);
      this.obstacles.push(obstacle);
    });
  }

  public updateConfig(config: GameConfig) {
    this.config = config;
  }

  public resize(width: number, height: number) {
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.p.resizeCanvas(width, height);
  }

  public setup(): void {
    this.p.createCanvas(this.canvasWidth, this.canvasHeight, this.p.WEBGL);
    this.p.pixelDensity(1);
    this.p.setAttributes({ antialias: false })

    this.p.mouseMoved = this.context.mouseMoved.bind(this.context);
    this.p.keyPressed = this.context.keyPressed.bind(this.context);
    this.p.keyReleased = this.context.keyReleased.bind(this.context);
    this.p.mouseDragged = this.context.mouseDragged.bind(this.context);

    this.world.setup();
    this.character.setup();
    this.obstacles.forEach((obstacle) => obstacle.setup());
  }

  public draw() {
    this.world.draw();
    this.p.push();
    this.p.translate(this.world.position);

    this.obstacles.forEach((obstacle) => obstacle.draw());

    // this.drawPath();

    this.character.draw();

    this.p.pop();
  }

  public dropTool(tool: ToolConfigWithId): boolean {
    let accepted = false;
    for (let obstacle of this.obstacles) {
      if (obstacle.checkDropTool(tool)) {
        obstacle.isCompleted = true;
        obstacle.currentTool = tool;
        obstacle.startAnimation('stars');
        accepted = true;
      }
    }
    return accepted;
  }

  public canDropTool(tool: ToolConfigWithId): boolean {
    return Boolean(this.obstacles.find((obstacle) => obstacle.checkDropTool(tool)));
  }

  private drawPath() {
    this.obstacles
      .filter((obstacle) => obstacle.isCompleted)
      .forEach((obstacle) => {
        this.p.noFill();
        this.p.stroke(255, 0, 0);
        this.p.strokeWeight(5);
        for (let block of obstacle.getBlocks()) {
          this.p.line(block.start.x, block.start.y, block.end.x, block.end.y);
        }
      });

    for (let block of this.world.getBlocks()) {
      this.p.noFill();
      this.p.stroke(255, 0, 0);
      this.p.strokeWeight(5);
      this.p.line(block.start.x, block.start.y, block.end.x, block.end.y);
    }
  }
}
