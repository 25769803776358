import { SetState } from "../../../../types";
import useUpdateData from "../hooks/useUpdateData";
import { Data, GameboardNames } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";
import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";

type Props = {
  data: Data;
  setData: SetState<Data>;
  gameboardNames: GameboardNames;
};

const SelectGameboardStep = ({ data, setData, gameboardNames }: Props) => {
  const updateData = useUpdateData();
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateData(data, e.target.value, "gameboard", "", setData, [
      { name: "", url: "" },
    ]);
  };

  const lastOptions = gameboardNames.map((gameboard) => (
    <SelectInputOption
      key={gameboard.id}
      option={{ name: gameboard.name, value: gameboard.id }}
    />
  ));

  const options = [
    <SelectInputOption
      key={"choose"}
      option={{ name: "Select A Gameboard", value: "choose" }}
    />,
    ...lastOptions,
  ];

  return (
    <CreatorDialogTemplate
      name="gameboard"
      mainText="Select A Gameboard"
      data={data}
      setData={setData}
      selectField={[{ options, handleChange }]}
    />
  );
};

export default SelectGameboardStep;
