import { SetState } from "../../../../types";
import Dialog from "../../../Components/dialog/Dialog";
import styles from "../creatorPage.module.scss";

type Props = {
  setProcess: SetState<undefined | "quickCreate" | "advanceCreate">;
};

const CreateProcessSelection = ({ setProcess }: Props) => {
  const mainContent = (
    <>
      <div className={styles.createProcessSelectionTexts}>
        <div className={`${styles.mainText} text-center`}>
          Select A Create Option
        </div>
        <div className={`${styles.subText} text-center`}>
          For a quicker and easier experince, select Quick Create!
        </div>
      </div>

      <div className={styles.createProcessSelectionBtns}>
        <button
          className={`${styles.btn} btn ${styles.continueBtn}`}
          onClick={() => setProcess("quickCreate")}
        >
          Quick Create
        </button>
        <button
          className={`${styles.btn} btn ${styles.continueBtn}`}
          onClick={() => setProcess("advanceCreate")}
        >
          Advance Create
        </button>
      </div>
      <div></div>
    </>
  );
  return <Dialog content={mainContent} />;
};

export default CreateProcessSelection;
