import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";

import { Data } from "../types";
import useUpdateData from "../hooks/useUpdateData";
import { SetState } from "../../../../types";

import styles from "../creatorPage.module.scss";
import CreatorDialogTemplate from "./CreatorDialogTemplate";
import { useEffect } from "react";

type Props = {
  data: Data;
  setData: SetState<Data>;
  currentRewardTypes: string[];
};

const TaskCompletedStep = ({ data, setData, currentRewardTypes }: Props) => {
  const updateData = useUpdateData();

  useEffect(() => {
    if (
      data.taskCompleted.rewardType?.value === "" ||
      data.taskCompleted.rewardType?.value === "choose"
    ) {
      setData((prev) => {
        const rewardAmountValue = prev.taskCompleted.rewardAmount?.value
          ? prev.taskCompleted.rewardAmount?.value
          : null;
        const taskCompletedValue = prev.taskCompleted.rewardType?.value
          ? prev.taskCompleted.rewardType?.value
          : "";
        const rewardAmountPattern = prev.taskCompleted.rewardAmount?.pattern
          ? prev.taskCompleted.rewardAmount?.pattern
          : "";
        const maxAllowedValue = prev.taskCompleted.maxAllowed?.value
          ? prev.taskCompleted.maxAllowed?.value
          : null;
        const maxAllowedPattern = prev.taskCompleted.maxAllowed?.pattern
          ? prev.taskCompleted.maxAllowed?.pattern
          : "";
        const resetPeriodValue = prev.taskCompleted.resetPeriod?.value
          ? prev.taskCompleted.resetPeriod?.value
          : null;
        const restPeriodPattern = prev.taskCompleted.resetPeriod?.pattern
          ? prev.taskCompleted.resetPeriod?.pattern
          : "";

        return {
          ...prev,
          taskCompleted: {
            ...prev.taskCompleted,
            value: [taskCompletedValue],
            rewardAmount: {
              value: rewardAmountValue,
              err: "",
              display: styles.hidden,
              pattern: rewardAmountPattern,
            },
            maxAllowed: {
              value: maxAllowedValue,
              err: "",
              display: styles.hidden,
              pattern: maxAllowedPattern,
            },
            resetPeriod: {
              value: resetPeriodValue,
              pattern: restPeriodPattern,
              err: "",
              display: styles.hidden,
            },
          },
        };
      });
    } else {
      setData((prev) => {
        const rewardAmountValue = prev.taskCompleted.rewardAmount?.value
          ? prev.taskCompleted.rewardAmount?.value
          : null;
        const taskCompletedValue = prev.taskCompleted.rewardType?.value
          ? prev.taskCompleted.rewardType?.value
          : "";
        const rewardAmountPattern = prev.taskCompleted.rewardAmount?.pattern
          ? prev.taskCompleted.rewardAmount?.pattern
          : "";
        const maxAllowedValue = prev.taskCompleted.maxAllowed?.value
          ? prev.taskCompleted.maxAllowed?.value
          : null;
        const maxAllowedPattern = prev.taskCompleted.maxAllowed?.pattern
          ? prev.taskCompleted.maxAllowed?.pattern
          : "";
        const resetPeriodValue = prev.taskCompleted.resetPeriod?.value
          ? prev.taskCompleted.resetPeriod?.value
          : null;
        const restPeriodPattern = prev.taskCompleted.resetPeriod?.pattern
          ? prev.taskCompleted.resetPeriod?.pattern
          : "";
        return {
          ...prev,
          taskCompleted: {
            ...prev.taskCompleted,
            value: [taskCompletedValue],
            rewardAmount: {
              value: rewardAmountValue,
              pattern: rewardAmountPattern,
              err: "",
              display: styles.display,
            },
            maxAllowed: {
              value: maxAllowedValue,
              pattern: maxAllowedPattern,
              err: "",
              display: styles.display,
            },
            resetPeriod: {
              value: resetPeriodValue,
              pattern: restPeriodPattern,
              err: "",
              display: styles.display,
            },
          },
        };
      });
    }
  }, [data.taskCompleted.rewardType]);

  const handleChange = (value: string, field: string) => {
    updateData(data, value, "taskCompleted", field, setData, [
      { name: "", url: "" },
    ]);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    handleChange(e.target.value, "rewardType");

  const middleOptions = currentRewardTypes.map((rewardType) => (
    <SelectInputOption
      key={rewardType}
      option={{ name: rewardType, value: rewardType }}
    />
  ));

  const options = [
    <SelectInputOption
      key={"choose"}
      option={{ name: "Select A Reward Type", value: "choose" }}
    />,
    middleOptions,
  ];

  const subText =
    "Configure the reward for completing the given task and the frequency that the task can be completed.";

  const inputField = [
    {
      placeholder: "Reward Amount",
      subField: "rewardAmount",
      patternErr: "Please enter a valid number",
    },
    {
      placeholder: "Max Allowed",
      subField: "maxAllowed",
      patternErr: "Please enter a valid number",
    },
    {
      placeholder: "Time Reset Period",
      subField: "resetPeriod",
      patternErr: "Please enter a valid number",
    },
  ];

  return (
    <CreatorDialogTemplate
      name="taskCompleted"
      data={data}
      setData={setData}
      mainText="Configure Rewards"
      subText={subText}
      selectField={[
        {
          options,
          handleChange: handleSelectChange,
          value: { field: "taskCompleted", subField: "rewardType" },
        },
      ]}
      inputField={inputField}
    />
  );
};

export default TaskCompletedStep;
