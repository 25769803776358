import { SetState, RenderableContent, Mission } from "../../../../types";
import { Data } from "../types";

import useNavigateDialog from "./useNavigateDialog";
import useSetDialogHook from "./useSetDialogHook";

const taskOptionDataFormatter = (
  taskOption: { name: string; url: string }[]
): { [key: string]: string } => {
  const options: { [key: string]: string } = {};
  for (const obj of taskOption) {
    if (!obj.name || !obj.url) continue;
    options[obj.name.trim()] = obj.url;
  }
  return options;
};

const useUpdateCreatorDialog = () => {
  const setDialogHook = useSetDialogHook();
  const navigateDialog = useNavigateDialog();

  const updateCreatorDialog = (
    name: string,
    currentStep: RenderableContent,
    currentStepNum: number,
    stepLength: number,
    setCurrentStepNum: SetState<number>,
    setDialogContent: SetState<RenderableContent>,
    data: Data,
    setData: SetState<Data>,
    addMissionHandler: (
      missions: Omit<Mission, "_id">,
      addToGame: string,
      createProcess: string
    ) => Promise<RenderableContent>
  ) => {
    let continueBtn: "Next" | "Create" = "Next";
    let handleContinueBtnClick: () => void;
    if (stepLength - 1 === currentStepNum) {
      continueBtn = "Create";
      let category = data.category.value[0];
      if (category === "new") category = data.category.inputField?.value!;
      let categoryImg = data.categoryIcon.img.value[0].url;

      let task: string = data.task.value[0].trim();
      const p1Task = data.task.value[0].split("___")[0];
      const p2Task = data.task.value[0].split("___")[1];

      if (p2Task === " " || p2Task === "" || !p2Task) task = p1Task.trim();

      const newMission: Omit<Mission, "_id"> = {
        category: category.trim(),
        image: categoryImg,
        task: task,
        optionsType: data.taskOption.value[0],
        maxAllowed: {
          amount: data.taskCompleted.maxAllowed?.value!,
          period: data.taskCompleted.resetPeriod?.value!,
        },
        rewardAmount: data.taskCompleted.rewardAmount?.value!,
        rewardType: data.taskCompleted.rewardType?.value.toString()!,
        teachersBonus:
          data.createProcess.value[0] === "quickCreate" ? true : false,
      };

      if (data.taskOption.value[0] === "image")
        newMission.options = taskOptionDataFormatter(
          data.optionsIcons.img.value
        );

      handleContinueBtnClick = async () => {
        const content = await addMissionHandler(
          newMission,
          data.addToGame.value[0],
          data.createProcess.value[0]
        );
        setDialogContent(content);
      };
    } else {
      handleContinueBtnClick = () =>
        navigateDialog(
          name,
          data,
          setData,
          currentStepNum,
          setCurrentStepNum,
          1,
          stepLength
        );
    }

    const setDialogHookProps = {
      setDialogContent,
      currentStep,
      handleContinueBtnClick,
      continueBtn,
      name,
      data,
      setData,
      currentStepNum,
      setCurrentStepNum,
      stepLength,
    };

    setDialogHook(setDialogHookProps);
  };
  return updateCreatorDialog;
};

export default useUpdateCreatorDialog;
